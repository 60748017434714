.sider-nav-wrap {
    .ant-drawer-body {
        padding: 0;
    }
}

.sider-nav-header {
    width: calc(100% - 32px);
    height: 60px;
    background: #FFFFFF;
    margin: 0 16px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    .logo {
        width: 126px;
        cursor: pointer;
    }
    .btns {
        display: flex;
        align-items: center;
        .close-btn {
            font-size: 18px;
            margin-left: 12px;
            cursor: pointer;
        }
    }
}

.sider-nav-list {
    width: 100%;
    padding: 12px 20px;
    font-family: 'SourceHanSansCN-Medium';
    font-size: 14px;
    color: #0C1939;
    .menu-item {
        width: 100%;
        line-height: 32px;
        margin-bottom: 8px;
        cursor: pointer;
        .arrow-svg-icon {
            transition: transform 0.24s;
        }
        &.expend {
            .arrow-svg-icon {
                transform: rotate(-180deg);
            }
        }
    }
}

.sider-nav-bottom {
    width: 100%;
    padding: 40px 16px;
    .sighup-btn {
        width: 100%;
        margin-top: 16px;
    }
    .login-btn {
        width: 100%;
    }
}
@primary-color: #24E6A9;